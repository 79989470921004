import { JSX } from "react"

interface CheckmarkListProps {
    children: JSX.Element | JSX.Element[]
}

const CheckmarkList = ({children} : CheckmarkListProps) => { 
    return (
        <ul>
          {children}
        </ul>
    )
}

export default CheckmarkList