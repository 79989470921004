import CheckmarkIcon from "components/icons/CheckmarkIcon"
import CloseIcon from "components/icons/CloseIcon"
import { JSX } from "react"

interface Props {
    children?: JSX.Element | JSX.Element[] |string
    icon?: boolean
    iconAsterix?: boolean
    iconAsterixx?: boolean
    iconAsterixxx?: boolean
    heading?: boolean
    hasAsterix?: boolean
}
const CompareTableCell = ({children, icon, heading, hasAsterix=false, iconAsterix, iconAsterixx, iconAsterixxx} : Props) => {
    
    return icon ? 
        <div className="px-5 py-2 bg-white font-bold text-secondary flex justify-center text-center items-center min-h-tableCell max-h-tableCell border-b"><CheckmarkIcon fillColor={'#00B26E'} className="h-4 w-4"/></div> :
        iconAsterix ? 
        <div className="px-5 py-2 bg-white text-secondary  flex justify-center text-center items-center min-h-tableCell max-h-tableCell border-b"><div className='relative'><CheckmarkIcon fillColor={'#00B26E'} className="h-4 w-4"/> <span className='absolute -top-2 -right-4'>*</span></div></div> :   
        iconAsterixx ?
        <div className="px-5 py-2 bg-white text-secondary  flex justify-center text-center items-center min-h-tableCell max-h-tableCell border-b"><div className='relative'><CheckmarkIcon fillColor={'#00B26E'} className="h-4 w-4"/> <span className='absolute -top-2 -right-4'>**</span></div></div> :
        iconAsterixxx ?
        <div className="px-5 py-2 bg-white text-secondary  flex justify-center text-center items-center min-h-tableCell max-h-tableCell border-b"><div className='relative'><CheckmarkIcon fillColor={'#00B26E'} className="h-4 w-4"/> <span className='absolute -top-2 -right-4'>***</span></div></div> :       
        !icon && !children ? 
        <div className="px-5 py-2 bg-white text-secondary  flex justify-center text-center items-center min-h-tableCell max-h-tableCell border-b"><div className='relative'><CloseIcon fillColor={'#F92121'} className="h-4 w-4"/>{hasAsterix && <span className='absolute -top-2 -right-4'>**</span>}</div></div> :
        <div className={`${heading ? 'z-10 font-bold left-0 sticky md:left-auto md:relative shadow-lg md:shadow-none border-r md:border-r-0':' justify-center text-center'} flex px-5 py-2 bg-white items-center text-secondary  min-h-tableCell max-h-tableCell border-b`}>{children}</div>
}

export default CompareTableCell