import { useDidMountEffect } from "hooks/UseDidMountEffect"
import { Dispatch, JSX, SetStateAction, useState } from "react"

interface Props {
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
    children: JSX.Element
}
const Modal = ({show, setShow, children}: Props) => {
    const [transition, setTransition]= useState<boolean>(false)
    
    const hideModal = (event: any) => {
        if(event.target.id === "mymodal") setShow(false)
    }

    useDidMountEffect(()=> {
        const time: number = show? 200: 0
        setTimeout(() => setTransition(show), time)
    }, [show])

    return (
        <div onClick={hideModal} id="mymodal" className={`fixed z-50 top-0 p-4 bottom-0 left-0 right-0 flex flex-wrap justify-center content-center opacity-0 ${show? '': 'hidden'} ${transition? ' opacity-100': ''} transition-opacity duration-500 ease-in-out`} style={{"backgroundColor": "rgba(0, 49, 89, .9)"}}>
            {children}
        </div>
    )
}

export default Modal