import LogoHeader from "../icons/LogoHeader"
import { MenuItem } from "./Navigation"
import Language from "components/buttons/Language"
import { useTranslation } from "react-i18next"
interface DesktopNavigationProps {
    menuItems: MenuItem[]
}

export default ({menuItems}: DesktopNavigationProps) => {
    const {t} = useTranslation()

    return (
        <div className="z-20 sticky top-0 h-header bg-white w-full flex shadow">
            <div className="flex container mx-auto items-center justify-between px-4">
                <div className="flex items-center">
                    <LogoHeader />
                </div>
                <div className="flex items-center self-stretch">
                   {
                        menuItems.map((item, key)=> (
                                <li key={key} className="border-b-4 self-stretch flex ml-7 items-center justify-center pt-1 border-white">
                                    <a className="font-bold" href={item.url}>{t(`navigation.${item.title}`)}</a>
                                </li>
                            )
                        )
                    }
                    <div className="px-7">
                        <Language/>
                    </div>
                </div>
            </div>
        </div>
    )
}