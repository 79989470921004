import { JSX } from "react"

interface TextProps {
    inverted?: boolean
    bold?: boolean
    children: JSX.Element | JSX.Element[] | string
    center?: boolean
    className?: string
}

const Text = ( {inverted = false, bold = false, children, center = false, className = undefined} : TextProps) =>  (
    <p className={`${className || ''} mb-10 ${bold ? 'font-bold': ''} ${inverted? 'text-white': ''} ${center ? 'text-center': ''}`}>{children}</p>
)

export default Text