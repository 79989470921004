import { JSX } from "react"

interface Props {
    children?: JSX.Element | JSX.Element[] | string
}
const CompareTableHeader = ({children} : Props) => {
    return (
        <div className={`bg-quartanary p-5 flex justify-center min-h-tableCell max-h-tableCell whitespace-nowrap ${children === undefined ? 'h-16 left-0 sticky md:left-auto md:relative border-r md:border-r-0' : ''}`}>{children}</div>
    )
}

export default CompareTableHeader