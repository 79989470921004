import {  Suspense, } from 'react'

import { StateProvider } from "store/GlobalState"

import ReactGA from 'react-ga4'
import "i18n/config"
import Home from 'pages/home/Home'
import Loading from 'components/Loading'

const TRACKING_ID = "G-Z121VGE1YT" // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

export default () => (
  <StateProvider>
    <Suspense fallback={<Loading />}>
     <Home />
      
          
       
   
   
    </Suspense>
  </StateProvider>
)

