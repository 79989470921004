import { createContext, useContext, useReducer, Dispatch, useEffect, JSX } from 'react'

import { GlobalState } from './GlobalStateModel'
import GlobalReducer from './GlobalReducer'
import { GlobalActionType } from './GlobalActionTypes'
import { LOCAL_STATE_STORAGE } from './GlobalVariables'

const initialState: GlobalState = {
	refresh: 0,
	loading: false,
	showDemoModal: false,
	showContactModal: false,
	showPrototypeModal: false
} as unknown as GlobalState

const StateContext = createContext<[GlobalState, Dispatch<GlobalActionType>]>([initialState, {} as Dispatch<GlobalActionType>])

const localState = sessionStorage.getItem(LOCAL_STATE_STORAGE) ? JSON.parse(sessionStorage.getItem(LOCAL_STATE_STORAGE) || '') : null

interface Props {
	children: JSX.Element | JSX.Element[]
}

export const useStateValue = () => useContext<[GlobalState, Dispatch<GlobalActionType>]>(StateContext)

export const StateProvider = ({ children }: Props) => {
	const [state, dispatch] = useReducer(GlobalReducer, localState || initialState)

	useEffect(() => {
		sessionStorage.setItem(LOCAL_STATE_STORAGE, JSON.stringify(state))
	}, [state])

	return (
		<StateContext.Provider value={[state, dispatch]}>
			{children}
		</StateContext.Provider>
	)
}
